/* eslint-disable react-hooks/exhaustive-deps */
import styled from "styled-components"

import { theme } from "@/common/Mixin"

export interface CheckListItem {
  title?: string
  description?: string
  noIcon?: boolean
}

export interface CheckList {
  title?: string
  titleClass?: string
  listColor?: "disabled" | "contrast" | "lightGray"
  list: CheckListItem[]
}

export interface CheckListLayoutProps {
  flexDirection: "row" | "column"
}

export const CheckListLayoutWrapper = styled.div<CheckListLayoutProps>`
  display: flex;
  flex-direction: ${props => props.flexDirection};
  gap: 48px;
  @media (${theme.lg}) {
    flex-direction: column;
  }
`

export const CheckListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const CheckListTitleWrapper = styled.span`
  color: ${theme.color.primary};

  &.contrast {
    color: ${theme.color.textContrast};
  }
`

export const CheckListItemWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  @media (${theme.lg}) {
    // padding-left: 5vw;
    gap: 4px;
  }
`

export const CheckListItemTitle = styled.span`
  color: ${theme.color.primary};
  padding-right: 8px;
  font-weight: 900;
`

export const CheckListItemDesc = styled.span<{
  color?: "disabled" | "contrast" | "lightGray"
}>`
  color: ${({ color = "contrast" }) => {
    switch (color) {
      case "disabled":
        return theme.color.textDisabled
      case "contrast":
        return theme.color.textContrast
      case "lightGray":
        return theme.color.textLightGray
      default:
        return theme.color.textContrast
    }
  }};

  @media (${theme.lg}) {
    text-align: left;
  }
`
