export enum ProductNames {
  AllProducts = "AllProducts",
  User360 = "User360",
  WalletBuilder = "WalletBuilder",
  WalletExtension = "WalletExtension",
  WalletSDK = "WalletSDK",
  WalletAPI = "WalletAPI",
  WalletApplication = "WalletApplication",
  AssetPro = "AssetPro",
  CompliancePro = "CompliancePro",
  ComplianceLite = "ComplianceLite",
  ComplianceAPI = "ComplianceAPI",
  ComplyFlow = "ComplyFlow",
  OAuthSDK = "OAuthSDK",
  NFTAirdrop = "NFTAirdrop",
  NFTBoost = "NFTBoost"
}

export enum ProductCategories {
  Analytics = "Analytics",
  Wallet = "Wallet",
  Compliance = "Compliance",
  NFT = "NFT",
  Onboarding = "Onboarding"
}

export const ProductCat2Names: Record<string, ProductNames[]> = {
  [ProductCategories.Analytics]: [ProductNames.User360],
  [ProductCategories.Wallet]: [
    ProductNames.WalletBuilder,
    ProductNames.WalletSDK,
    ProductNames.WalletAPI,
    ProductNames.WalletApplication,
    ProductNames.WalletExtension,
    ProductNames.AssetPro
  ],
  [ProductCategories.Compliance]: [
    ProductNames.CompliancePro,
    ProductNames.ComplianceLite,
    ProductNames.ComplianceAPI,
    ProductNames.ComplyFlow
  ],
  [ProductCategories.NFT]: [ProductNames.NFTAirdrop, ProductNames.NFTBoost],
  [ProductCategories.Onboarding]: [ProductNames.OAuthSDK]
}
