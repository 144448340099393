import React from "react"
import styled from "styled-components"
import { theme } from "@/common/Mixin"

export interface ProductHeadingProps {
  content: string
  primary?: boolean
  className?: string
  [x: string]: any
}

const ProductHeading = ({
  content,
  primary,
  className,
  ...props
}: ProductHeadingProps) => {
  const classNames = `${primary ? "primary" : ""} ${className}`

  return (
    <HeadingWrapper className={classNames} {...props}>
      {content}
    </HeadingWrapper>
  )
}

export default ProductHeading

interface HeadingWrapperProps {
  themeNew?: any
}

const HeadingWrapper = styled.div<HeadingWrapperProps>`
  color: ${theme.color.textContrast};
  white-space: pre-line;
  &.primary {
    color: ${theme.color.primary};
  }
`
