import styled from "styled-components"
import { theme } from "@/common/Mixin"

export const LayoutWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr;
  gap: 60px;
`

export const DarkBGWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: all 1s ease;
  background-color: ${theme.color.dark};
`
