import styled, { css } from "styled-components"
import { themeGet } from "@styled-system/theme-get"

export const theme = {
  color: {
    primary: themeGet("colors.primary"),
    primaryDark: themeGet("colors.primaryDark"),
    primaryLight: themeGet("colors.primaryLight"),
    primaryLinear: themeGet("colors.primaryFamily.linear"),

    linearBack: themeGet("colors.linearFamily.back"),
    linearLight: themeGet("colors.linearFamily.light"),

    secondary: themeGet("colors.secondary"),
    secondaryLight: themeGet("colors.secondaryLight"),
    dark: themeGet("colors.dark"),

    background: themeGet("colors.background"),
    backgroundLight: themeGet("colors.backgroundFamily.light"),
    backgroundBlack: themeGet("colors.backgroundFamily.black"),
    backgroundL2: themeGet("colors.backgroundFamily.L2"),

    title: themeGet("colors.title"),
    titleHighlight: themeGet("colors.titleHighlight"),

    text: themeGet("colors.text"),
    textBlack: themeGet("colors.textFamily.black"),
    textGray: themeGet("colors.textFamily.gray"),
    textLightGray: themeGet("colors.textFamily.lightGray"),
    textContrast: themeGet("colors.textFamily.contrast"),
    textDisabled: themeGet("colors.textFamily.disabled"),
    textSecondary: themeGet("colors.textFamily.secondary"),
    lightText: themeGet("colors.lightText"),
    textShadowLight: themeGet("colors.textShadowLight"),
    textShadowDark: themeGet("colors.textShadowDark"),

    checkBoxFill: themeGet("colors.boxFill"),
    radioFill: themeGet("colors.boxFill"),

    headingColor: themeGet("colors.headingColor"),
    white: themeGet("colors.white"),
    primaryButtonText: themeGet("colors.primaryButtonText"),
    error: themeGet("colors.error"),
    buttonHover: "rgba(255, 255, 255, 0.1)",
    header: "#08122C",

    transparentHover: themeGet("colors.transparent.hover")
  },
  gradient: {
    bg: "linear-gradient(-135deg, #032156 0%, #011538 100%)",
    light:
      "linear-gradient(45deg, rgba(255, 194, 17,0.3) 0%, rgba(33, 77, 159,0.3) 100%)",
    textdark: `linear-gradient(45deg, ${themeGet("colors.primary")}, #FFAE11`
  },
  menuBG: "background-color:#03122ecc;backdrop-filter: blur(10px);",
  ssm: themeGet("ssm"),
  sm: themeGet("sm"),
  md: themeGet("md"),
  lg: themeGet("lg"),
  xl: themeGet("xl"),
  xxl: themeGet("xxl")
}
//mixins-------------------------------------------------------
export const flex = ({
  j,
  a,
  d,
  g
}: {
  j?: any
  a?: any
  d?: any
  g?: any
} = {}) => css`
  display: flex;
  justify-content: ${j || "center"};
  align-items: ${a || "center"};
  flex-direction: ${d || "row"};
  gap: ${g || null};
`

interface BtnProps {
  themeNew?: any
}

export const btnP = css<BtnProps>`
  background: linear-gradient(
    -139deg,
    ${({ themeNew }) => (themeNew ? themeNew.primary : theme.color.primary)} 0%,
    ${({ themeNew }) =>
        themeNew ? themeNew.secondary : theme.color.primaryDark}
      100%
  );
  border: 1px solid transparent;
  box-shadow: 0px 8px 20px
    ${({ themeNew }) => (themeNew ? themeNew.primary : theme.color.primary)}66;
  color: ${theme.color.secondary};
`
export const btnS = css<BtnProps>`
  background: linear-gradient(
    ${({ themeNew }) => (themeNew ? themeNew.primary : theme.color.primary)}33
      0%,
    ${({ themeNew }) => (themeNew ? themeNew.primary : theme.color.primary)}00
      100%
  );
  border: 1px solid
    ${({ themeNew }) => (themeNew ? themeNew.primary : "#ffc2117f")};
  color: ${({ themeNew }) =>
    themeNew ? themeNew.primary : theme.color.primaryDark};
  box-shadow: 0px 8px 20px
    ${({ themeNew }) => (themeNew ? themeNew.primary : theme.color.primary)}66;
`
export const btnShadow = css<BtnProps>`
  box-shadow: 0px 8px 20px
    ${({ themeNew }) => (themeNew ? themeNew.primary : theme.color.primary)}66;
  transition: all 0.3s;
  &:hover {
    box-shadow:
      0px 16px 40px
        ${({ themeNew }) =>
          themeNew ? themeNew.primary : theme.color.primary}66,
      inset 0px 0px 10px ${theme.color.white}66;
  }
  @media (${theme.md}) {
    box-shadow: 0px 4px 12px
      ${({ themeNew }) => (themeNew ? themeNew.primary : theme.color.primary)}66;
    &:focus,
    &:hover {
      box-shadow:
        0px 4px 16px
          ${({ themeNew }) =>
            themeNew ? themeNew.primary : theme.color.primary}66,
        inset 1px 1px 5px
          ${({ themeNew }) =>
            themeNew ? themeNew.primary : theme.color.primary}44;
    }
  }
`
export const section = ({ my, py }: { my?: any; py?: any } = {}) => css`
  width: clamp(0px, 100%, var(--maxWidth));
  padding: ${py || "30px"} var(--paddingX);
  margin: ${my || "60px"} auto;
`
export const text = () => css`
  background: linear-gradient(
    to right,
    ${theme.color.title},
    ${theme.color.titleHighlight}
  );
  background-clip: text;
  -webkit-background-clip: text;
  display: inline-block;
  -webkit-text-fill-color: transparent;
`
export const textNew = () => css`
  background: linear-gradient(
    to right,
    ${theme.color.primary},
    ${theme.color.primaryDark}
  );
  background-clip: text;
  -webkit-background-clip: text;
  display: inline-block;
  -webkit-text-fill-color: transparent;
`

export const sectionFull = css`
  width: clamp(0px, 100%, 100%);
  margin: 60px 0;
`
//styleds-------------------------------------------------------

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: var(--textGap);
  flex-wrap: wrap;
  /* padding-top: 16px; */
  &.center {
    justify-content: center;
  }
  @media (${theme.lg}) {
    width: 100%;
    justify-content: center;
  }
  @media (${theme.ssm}) {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .react-reveal {
    width: auto !important;
  }
`
export const BGWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;
  &.nohidden {
    overflow: visible;
  }
  &.fixed {
    position: fixed;
    height: 120%;
  }
`
export const DesktopOnly = styled.div`
  @media (${theme.lg}) {
    display: none;
  }
`
export const PhoneOnly = styled.div`
  display: none;
  @media (${theme.lg}) {
    display: block;
  }
`
export const OverflowWrapper = styled.div`
  overflow: hidden;
`
