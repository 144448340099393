import React from "react"
import styled from "styled-components"
import {
  fontFamily,
  fontWeight,
  textAlign,
  lineHeight,
  letterSpacing
} from "styled-system"
import { base, themed } from "../base"

// Define the interface for props
interface TextProps {
  content?: string
  as?: keyof JSX.IntrinsicElements // Use keyof JSX.IntrinsicElements for 'as' prop
  mt?: string | number | (string | number)[]
  mb?: string | number | (string | number)[]
  fontFamily?: string | number | (string | number)[]
  fontWeight?: string | number | (string | number)[]
  textAlign?: string | number | (string | number)[]
  lineHeight?: string | number | (string | number)[]
  letterSpacing?: string | number | (string | number)[]
  children?: React.ReactNode // To allow children prop
  [key: string]: any // For additional styled-system props
}

// Update styled component to accept TextProps
const TextWrapper = styled.p<TextProps>(
  base,
  fontFamily,
  fontWeight,
  textAlign,
  lineHeight,
  letterSpacing,
  themed("Text")
)

// Define the Text component
const Text: React.FC<TextProps> = ({
  content,
  as = "p",
  mt = 0,
  mb = "1rem",
  children,
  ...props
}) => (
  <TextWrapper as={as} mt={mt} mb={mb} {...props}>
    {content || children}
  </TextWrapper>
)

export default Text
