/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import styled from "styled-components"
import ProductHeading from "@/common/components/_UI/ProductHeading"
import NextImg from "@/common/components/NextImg/index"

import { theme } from "@/common/Mixin"
import Button, { ButtonProps } from "@/common/components/_UI/ButtonNew"
import CheckIcon from "@/common/assets/image/contactUs/check.svg"
import {
  CheckList,
  CheckListItemDesc,
  CheckListItemTitle,
  CheckListItemWrapper,
  CheckListTitleWrapper,
  CheckListWrapper
} from "./CheckListStyle"

const Fade = require("react-reveal/Fade")

export interface IntroButtonProps extends ButtonProps {
  element?: any
}

export interface IntroductionProps {
  icon?: any
  title?: string
  titleType?: "primary" | "normal"
  titleh1?: string
  titleh2?: string
  titleh3?: string
  titleh3Type?: "primary" | "normal"
  body?: string
  bodyType?: "primary" | "normal"
  description?: string
  checkList?: CheckList[]
  primaryButton?: IntroButtonProps
  subtleButton?: IntroButtonProps
  buttonList?: IntroButtonProps[]
  comp2Position?: "left" | "right"
  comp2?: React.ReactNode
  style?: React.CSSProperties
  comp1Style?: React.CSSProperties
}

// +-----------------------+
// |       Introduction    |
// |                       |
// |  +-------+----------+ |
// |  |icon,  |  comp2   | |
// |  |title  |          | |
// |  |titleh1|          | |
// |  |titleh2|          | |
// |  |titleh3|          | |
// |  |body   |          | |
// |  |descri.|          | |
// |  |checkList         | |
// |  |primaryButton     | |
// |  |subtleButton      | |
// |  +-------+----------+ |
// |                       |
// +-----------------------+
const Introduction = ({
  icon,
  title,
  titleType = "normal",
  titleh1,
  titleh2,
  titleh3,
  titleh3Type = "primary",
  body,
  bodyType = "primary",
  description,
  checkList,
  primaryButton,
  subtleButton,
  buttonList,
  comp2Position = "right",
  comp2,
  style,
  comp1Style
}: IntroductionProps) => {
  const renderButton = (
    buttonProp: IntroButtonProps | undefined,
    type: "primary" | "subtle"
  ) => {
    if (buttonProp?.element != null) {
      return buttonProp?.element
    }
    return (
      buttonProp?.content && (
        <Button
          styleType={type}
          content={buttonProp?.content}
          href={buttonProp?.href}
          target={buttonProp?.target ?? "_blank"}
          onClick={buttonProp?.onClick}
          arrowType={buttonProp?.arrowType ?? "right"}
        />
      )
    )
  }
  return (
    <Wrapper style={style}>
      {comp2 && <Comp2Wrapper position={comp2Position}>{comp2}</Comp2Wrapper>}
      <IntroWrapper style={comp1Style}>
        <TitleWrapper>
          {icon && (
            <NextImg style={{ width: "44px" }} src={icon} alt="Product Icon" />
          )}
          {title && (
            <HeadingWrapper className={`${titleType} title`}>
              {title}
            </HeadingWrapper>
          )}
        </TitleWrapper>
        {titleh1 && (
          <Fade bottom>
            <ProductHeading as="h1" content={titleh1} />
          </Fade>
        )}
        {titleh2 && (
          <Fade bottom>
            <ProductHeading as="h2" content={titleh2} />
          </Fade>
        )}
        {titleh3 && (
          <ProductHeading as="h3" className={titleh3Type} content={titleh3} />
        )}
        {body && (
          <TextWrapper className={`${bodyType} body-bold`}>{body}</TextWrapper>
        )}
        {description && (
          <TextWrapper className="body">{description}</TextWrapper>
        )}
        {checkList &&
          checkList.map((list, index) => (
            <CheckListWrapper key={`${list.title}_${index}`}>
              <CheckListTitleWrapper className="body-bold">
                {list.title}
              </CheckListTitleWrapper>
              {list.list.map((item, index) => (
                <CheckListItemWrapper key={index}>
                  <NextImg
                    style={{ width: "24px" }}
                    src={CheckIcon.src}
                    alt="Check Icon"
                  />
                  <p>
                    {item.title && (
                      <CheckListItemTitle className="body">
                        {item.title}
                      </CheckListItemTitle>
                    )}
                    {item.description && (
                      <CheckListItemDesc
                        color={list.listColor}
                        className="body"
                      >
                        {item.description}
                      </CheckListItemDesc>
                    )}
                  </p>
                </CheckListItemWrapper>
              ))}
            </CheckListWrapper>
          ))}

        <ButtonWrapper>
          {renderButton(primaryButton, "primary")}
          {renderButton(subtleButton, "subtle")}
          {buttonList &&
            buttonList.map((button, index) => (
              <div key={index}>{renderButton(button, "primary")}</div>
            ))}
        </ButtonWrapper>
      </IntroWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  gap: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  overflow: hidden;
  width: 100%;
  align-items: center;

  @media only screen and (${theme.lg}) {
    display: flex;
    max-height: auto;
    flex-direction: column;
    height: auto;
    justify-content: center;
  }
`

const Comp2Wrapper = styled.div<{ position?: "left" | "right" }>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  order: ${({ position }) => (position === "left" ? 0 : 1)};
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
  @media (${theme.sm}) {
    flex-direction: row;
    // gap: 1em;
    align-items: center;
    a {
      width: 100% !important;
    }
    button {
      width: 100% !important;
    }
    // display: grid;
    justify-content: center;
  }
`

interface TopWrapperProps {
  locale?: string
}

const IntroWrapper = styled.span<TopWrapperProps>`
  color: ${theme.color.primary};
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 600px;
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

const HeadingWrapper = styled.span`
  color: ${theme.color.textContrast};

  &.primary {
    color: ${theme.color.primary};
  }
`

const TextWrapper = styled.span`
  color: ${theme.color.textDisabled};

  &.primary {
    color: ${theme.color.primary};
  }
`

export default Introduction
