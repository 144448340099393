export enum SolutionNames {
  AllSolutions = "AllSolutions",
  GameFi = "GameFi",
  Defi = "DeFi",
  Web3FundManagement = "Web3FundManagement",
  Web3Commerce = "Web3Commerce"
}

export enum SolutionPlans {
  GameFiPro = "GameFiPro",
  GameFiLite = "GameFiLite",
  DefiPro = "DefiPro",
  DefiLite = "DefiLite",
  Web3FundManagement = "Web3FundManagement",
  Other = "Other"
}

export type Web3FundManagementCardSection =
  | "defiKLine"
  | "money"
  | "shoppingStore"
  | "tether"
