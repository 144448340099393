import React from "react"
import Image, { StaticImageData } from "next/image"

export interface NextImgProps {
  src: string | StaticImageData
  className?: string
  alt?: string
  width?: number
  height?: number
  fill?: boolean
  priority?: boolean
  style?: React.CSSProperties
  placeholder?: "blur"
  id?: string
  sizes?: string
  loading?: "eager" | "lazy"
  quality?: number
}

export default function NextImg({
  src,
  className,
  width = 0,
  height = 0,
  alt = "img",
  priority,
  fill,
  loading,
  style = {},
  sizes = "100vw",
  placeholder = "blur",
  quality = 75,
  id
}: NextImgProps) {
  const applyStyle: React.CSSProperties = {
    ...style,
    width: fill ? undefined : width === 0 ? "100%" : width,
    height: fill ? undefined : height === 0 ? "auto" : height
  }
  return (
    <Image
      id={id}
      className={className}
      src={src}
      alt={alt}
      quality={quality}
      width={fill ? undefined : width}
      height={fill ? undefined : height}
      fill={fill}
      priority={priority}
      loading={loading}
      placeholder={placeholder}
      blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mOMLSr2BwAECQGT0IlefgAAAABJRU5ErkJggg=="
      sizes={sizes}
      style={{
        ...applyStyle,
        ...style,
        maxWidth: "100%",
        height: "auto"
      }}
    />
  )
}
