import styled from "styled-components"
import { colorStyle } from "../customVariant"
import { AnimSpinner } from "../Animation"
import { base } from "../base"

interface LoaderStyleProps {
  loaderColor?: string
}

const LoaderStyle = styled.span<LoaderStyleProps>`
  /* loader default style */
  display: inline-flex;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  overflow: hidden;
  border-width: 2px;
  border-style: solid;
  border-color: ${props => (props.loaderColor ? props.loaderColor : "#000000")};
  border-top-color: transparent !important;

  /* animation goes here */
  ${AnimSpinner}
  /* Style system custome color variant */
  ${colorStyle}
  ${base}
`

LoaderStyle.displayName = "LoaderStyle"

export default LoaderStyle
