import React from "react"
import { NextSeo } from "next-seo"

const MetaData = ({ title, description, url, image, languageAlternates }) => {
  return (
    <NextSeo
      title={title}
      description={description}
      canonical={url.includes("biweekly") === true ? null : url}
      languageAlternates={languageAlternates}
      openGraph={{
        type: "website",
        url,
        title,
        description,
        images: [
          {
            url: image,
            alt: "Og Image Alt"
          }
        ],
        site_name: "KryptoGO"
      }}
      twitter={{
        handle: "@kryptogo_",
        site: "@kryptogo_",
        cardType: "summary_large_image"
      }}
    />
  )
}

export default MetaData
