import React from "react"
import styled from "styled-components"

import { BGWrapper, theme } from "@/common/Mixin"
import { BannerType, getBackground, BannerImg } from "./BannerImages"
import Introduction, { IntroductionProps } from "../Introduction"

export interface ProductBannerProps extends IntroductionProps {
  bannerType?: BannerType
  paddingTop?: string
  id?: string
  className?: string
  style?: React.CSSProperties
  backgroundImage?: React.ReactNode
  short?: boolean
}

const ProductBanner = ({
  bannerType,
  className,
  comp2,
  paddingTop = "10vh",
  id,
  style,
  short = false,
  backgroundImage,
  ...props
}: ProductBannerProps) => {
  const background = backgroundImage ?? getBackground(bannerType)
  return (
    <div
      className={className}
      style={{
        position: "relative",
        ...style
      }}
      id={id}
    >
      <BGWrapper style={{ overflow: "visible" }}>{background}</BGWrapper>
      <BannerWrapper
        className="kg-wrapper"
        id="banner_section"
        paddingTop={paddingTop}
        short={short}
      >
        <Introduction
          {...props}
          comp2={comp2 ? comp2 : BannerImg({ bannerType })}
        />
      </BannerWrapper>
    </div>
  )
}

interface BannerWrapProps {
  paddingTop: string
  short: boolean
}

const BannerWrapper = styled.section<BannerWrapProps>`
  display: flex;
  padding-top: ${({ paddingTop }) => paddingTop};
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 40px;
  align-self: stretch;
  min-height: ${({ short }) => (short ? "auto" : "75vmin")};

  // apply h2 style for h1 for product banner does not need h1 big title
  h1 {
    font-size: 48px;
    font-weight: 600;
    line-height: 125%;
    letter-spacing: 0.96px;
  }

  @media (${theme.lg}) {
    flex-direction: column;
    align-items: flex-start;
    padding-top: clamp(10vh, 20vh, 96px);
    h1 {
      font-size: 32px;
    }
  }
`

export default ProductBanner
