import React, { ComponentProps, MouseEventHandler } from "react"
import styled from "styled-components"
import { theme, flex } from "@/common/Mixin"
import Link from "next/link"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import Loader from "../Loader"

export interface ButtonProps {
  content?: string
  type?: "reset" | "submit" | "button"
  styleType?: "primary" | "subtle" | "secondary" | "text" | "default"
  className?: string
  onClick?: MouseEventHandler<HTMLButtonElement>
  id?: string
  href?: ComponentProps<"a">["href"]
  target?: ComponentProps<"a">["target"]
  themeNew?: any
  arrowType?: "left" | "right" | "up" | "down" | "none"
  style?: React.CSSProperties
  loading?: boolean
  wide?: boolean
  disabled?: boolean
}

export default function Button({
  content,
  type = "button",
  styleType,
  className,
  onClick,
  id,
  href,
  target,
  themeNew,
  arrowType = "right",
  style,
  loading = false,
  wide = false,
  disabled = false
}: ButtonProps) {
  const renderIcon = () => {
    if (loading) {
      return <Loader loaderColor={"#30C56D"} />
    }
    switch (arrowType) {
      case "left":
        return <KeyboardArrowLeftIcon />
      case "right":
        return <KeyboardArrowRightIcon />
      case "up":
        return <KeyboardArrowUpIcon />
      case "down":
        return <KeyboardArrowDownIcon />
      case "none":
        return null
    }
  }
  const contentElement = (
    <Wrapper
      style={{
        ...style,
        width: wide ? "100%" : "auto"
      }}
      className={`${styleType} ${className ?? "button-l"}`}
      themeNew={themeNew}
      onClick={onClick}
      type={type}
      disabled={disabled || loading}
    >
      {content}
      {renderIcon()}
    </Wrapper>
  )
  if (href != null) {
    return (
      <Link
        style={{ width: wide ? "100%" : "auto" }}
        href={href ?? ""}
        target={target}
        id={id}
      >
        {contentElement}
      </Link>
    )
  } else {
    return contentElement
  }
}

interface WrapperProps {
  themeNew?: any
}

const Wrapper = styled.button<WrapperProps>`
  cursor: pointer;
  color: ${theme.color.secondary};
  background-color: ${theme.color.primary};
  border: 1px solid ${theme.color.primary};

  &:hover {
    background-color: ${theme.color.primaryDark};
  }
  &.subtle {
    border: 1px solid ${theme.color.primary};
    background-color: rgba(0, 0, 0, 0);
    color: ${theme.color.primary};
    &:hover {
      background-color: ${theme.color.transparentHover};
    }
  }
  &.secondary {
    background-color: ${theme.color.secondary};
    border: 1px solid ${theme.color.secondary};
    color: ${theme.color.white};

    &:hover {
      background-color: ${theme.color.background};
      color: ${theme.color.white};
    }
  }
  &.text {
    border: none;
    background-color: transparent;
    box-shadow: none;
    color: ${theme.color.textContrast};
    min-width: 0px;
    padding: 6px 16px;
    height: auto;
    &:hover {
      background-color: ${theme.color.transparentHover};
    }
  }
  &.lightGray {
    color: ${theme.color.textLightGray};
  }

  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(255, 255, 255, 0.1);
  height: 52px;
  padding: 12px 32px;
  min-width: 160px;
  transition: all 0.3s ease;
  ${() => flex()}

  @media (${theme.md}) {
    &:focus,
    &:hover {
    }
    @media (${theme.xl}) {
      padding: 12px 20px;
      font-size: 16px;
      &.min {
        height: 32px;
        padding: 4px 24px;
        font-size: 14px;
        min-width: 0px;
      }
    }
  }
`
